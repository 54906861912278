/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Add and Remove classes on specific width
        var $window = $(window);

        function checkWindowWidth(){
          var windowSize = $window.width();

          if( windowSize > 992){
            $('.dropdown-toggle').removeAttr( "data-toggle" );
            //Dropdown on hover
            $('body').on('mouseenter mouseleave','.dropdown',function(e){
              var _d=$(e.target).closest('.dropdown');_d.addClass('show');
              setTimeout(function(){
                _d[_d.is(':hover')?'addClass':'removeClass']('show');
              },300);
            });
          }else if( windowSize < 992 ){
            $('body').off('mouseenter mouseleave');
            $(".dropdown-toggle").attr('data-toggle', 'dropdown');
          }

        }
        checkWindowWidth();
        $window.resize(checkWindowWidth);



        //Nav hide
        var c = 0;
        var currentScrollTop = 0;
        var nav = $(".navbar");
        var scrolledDown = 10;
        var wrapperMenu = $('.wrapper-toggler');
        var hamburgerLines = $('.line-menu');

        var menuActiveState = function(){
          if( $(hamburgerLines).hasClass("solid") ){
            return true;
          }else{
            return false;
          }
        };
        $(wrapperMenu).click(function(){
          $(this).toggleClass('open');
          $(this).parent().toggleClass('opened');
          if( currentScrollTop < 10 && menuActiveState() ){
            $(this).find(".line-menu").removeClass('solid');
            $(".navbar-brand").removeClass("brand-solid");
            $(nav).removeClass("nav-solid");
          }else{
            $(nav).addClass("nav-solid");
            hamburgerLines.addClass('solid');
            $(".navbar-brand").addClass("brand-solid");
          }
        });

        $(window).scroll(function () {
          var a = $(window).scrollTop();
          var b = 10;

          currentScrollTop = a;

          if( currentScrollTop < 10){

            if( !$("#navbarSupportedContent").hasClass("show") ){
              $(nav).removeClass("nav-solid");
              hamburgerLines.removeClass('solid');
              $(".navbar-brand").removeClass("brand-solid");
              $(nav).addClass("transparent");
            }
          }

          if (c < currentScrollTop && a > b ) {

            nav.addClass("scrollUp");

          } else if (c > currentScrollTop ) {

            if( currentScrollTop > scrolledDown ){
              $(nav).addClass("nav-solid");
              hamburgerLines.addClass('solid');
              $(".navbar-brand").addClass("brand-solid");
              $(nav).removeClass("transparent");
            }

            nav.removeClass("scrollUp");

          }
          c = currentScrollTop;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'project_template_default': {
      init: function() {
        $('.slick').slick({
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 800,
          slidesToShow: 4,
          slidesToScroll: 1,
          pauseOnHover: true,
          nextArrow: $(".arrow-left"),
          prevArrow: $(".arrow-right"),
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 577,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
